import { colors } from './../../variables';
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  height: 50px;
  position: relative;
  padding-left: 40px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  @media (max-width: 991px){
    text-align: center;
    display: block;
    padding-left: 0px;
    padding-top: 15px;
  }
`;

const _Logo = styled.img<RouteComponentProps>`
  width: 160px;
  height: auto;
  position: fixed;
  left: 50%;
  top: 30px;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  margin-left: -80px;
  margin-right: 20px;
  @media (max-width: 991px){
    width: 185px;
    position: relative;
    top: inherit;
    z-index: 2;
    left: inherit;
    text-align: center;
    margin: 0 auto;
    top: 20px;
  }
  ${props => (
    props.match.path.startsWith('/message') 
  ) && css`
    @media (max-width: 991px){
      
    }
  `
  }
`;

const _Catchline = styled.h1<RouteComponentProps>`
  position: relative;
  font-size: 16px;
  color: #4a4a4a;
  position: fixed;
  left: 250px;
  top: 35px;
  display: none;
  br{
    display: none;
  }
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    margin-top: 15px;
    position: relative;
    font-size: 32px;
    line-height: 28px;
    color: #4a4a4a;
    display: none;
    br{
      display: block;
    }
  }

  ${props => (
    props.match.path.startsWith('/upload') ||
    props.match.path.startsWith('/preview') 
  ) && css`
    display: block;
    @media (max-width: 991px){
      display: none;
    }
    
  `
  }

${props => (
    props.match.path.startsWith('/message') ||
    props.match.path.startsWith('/record')
  ) && css`
    @media (max-width: 991px){
      display: block;
    }
    
  `
  }


${props => (
    props.match.path.startsWith('/message') 
  ) && css`
    @media (max-width: 991px){
      color: #000!important;
      z-index: 10;
    }
    
  `
  }
`;

export const Catchline = withRouter(_Catchline);
export const Logo = withRouter(_Logo);