import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import icoCross from './images/ico-cross.svg';
import icoOpenMobile from './images/ico-help-mobile-black.svg';
import icoOpenMobileBlack from './images/ico-help-mobile-black.svg';

export const HelpWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 50;
  @media (max-width: 991px){
    left: 0px;
    right: inherit;
    width: 100%;
    right: inherit;
    position: absolute;
    top: inherit;
  }
`;

const _HelpContainerOpenMobile = styled.div<RouteComponentProps>`
  ${props => (
      props.match.path.startsWith('/message')
    ) && css`
      @media (max-width: 991px){
        & > div{
          background-image: url(${icoOpenMobile});
        }
      }
      
    `
    }
`;


export const HelpContainer = styled.div<{active?: boolean}>`
  position: fixed;
  right: -500px;
  top: 0px;
  z-index: 40;
  background-color: #fff;
  width: 500px;
  height: 100vh;
  overflow-y: scroll;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  ${props => props.active && css`
      right: 0px;
    `}
  @media (max-width: 991px){
    width: 100%;
    box-shadow: inherit;
    -webkit-box-shadow: inherit;
    -moz-box-shadow: inherit;
    right: -100%;  
    ${props => props.active && css`
      right: 0%;
    `}
  }
`;

export const HelpContent = styled.div`
  padding: 100px 80px;
  color: #000;
  text-align: center;
  padding-top: 120px;
  font-size: 14px;
  h1{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  @media (max-width: 991px){
    padding: 100px 50px;
  }
  p, ul li{
    font-size: 14px;
    line-height: 26px;
    font-weight: 300;
  }
  ul{
    margin: 0px;
    padding: 10px 20px;
    font-weight: 300;
  }
`;

export const HelpClose = styled.div`
  position: absolute;
  top: 50px;
  left: 30px;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 0px;
  border: 0px solid #000;
  background-image: url(${icoCross});
  background-size: 25px auto;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 991px){
    right: 25px;
    top: 35px;
    left: inherit;
    z-index: 100;
  }
  &:hover{
    background-color: #ccc;
    border:1px solid #ccc;
  }
`;

export const HelpOpenMobile = styled.div<{active?: boolean}>`
  position: absolute;
  top: 30px;
  right: 20px;
  width: 25px;
  z-index: 50;
  height: 25px;
  background-image: url(${icoOpenMobileBlack});
  background-size: 25px auto;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  @media (min-width: 992px){
    display: none;
  }
  ${props => props.active && css`
      display: none;
    `}
`;


export const HelpList = styled.ul<{active?: boolean}>`
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
  color: #000;
  overflow: hidden;
  z-index: 40;
  position: relative;
  ${props => props.active && css`
    background-color: transparent;
    color: #000!important;
    li a{
      color: #000;
    }
  `}
  @media (max-width: 991px){
    display: none;
    margin-top: 0px;
    position: fixed;
    top: 30px;
    left: 10px;
    ${props => props.active && css`
      display: block;
    `}
  }
`;

export const HelpListItem = styled.li<{active?: boolean}>`
  display: inline-block;
  text-align: center;
  &:last-child{
    border-right: 0px;
  }
  a{
    color: #000;
    font-weight: 500;
    text-decoration: inherit;
    height: 20px;
    line-height: 20px;
    padding: 7px 10px;
    display: block;
    font-size: 12px;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    @media (max-width: 991px){
      font-size: 14px;
      padding: 0px 20px;
      height: 40px;
      line-height: 40px;
    }
    &:hover{
      background-color: #ddd;
      color: #000;
    }

    ${props => props.active && css`
      a{
        background-color: #000;
        color: #fff;
      }
    `}
  }
`;


export const HelpContainerOpenMobile = withRouter(_HelpContainerOpenMobile);