import * as React from 'react';
import Layout from '../layout';
import { InputLabel, IconLanding, LandingWrapper, Catchline, Sep, LandingMessage, LandingTitle, LandingContent, LandingFormContainer, LandingForm, InputField, InputGroup, ButtonGroup, InputInfoIcon, InputInfo } from './styled';
import { Button } from '../../widgets/globaleStyledWidget';
import { colors } from '../../variables';
import imgLanding from './images/ico-gift.png';

import {FormattedMessage, useIntl} from 'react-intl';
import { match, useHistory } from 'react-router-dom';
import livstickApp from '../../models/livstick';
import { ErrorInfo } from '../message/styled';
import axios from 'axios';



interface DetailParams {
  id: string;
}

interface DetailsProps {
  match?: match<DetailParams>;
}

const LandingPage:React.FunctionComponent<DetailsProps> = ({match}) => {
    const intl = useIntl();
    const history = useHistory();
    /* Info Input Number */
    const [InfoNumberActive, setInfoNumberActive] = React.useState<boolean>(false);


    const openNumberInfo = () => { setInfoNumberActive(true); }
    const closeNumberInfo = () => { setInfoNumberActive(false); }

    /* Info Input */
    const [InfoActive, setInfoActive] = React.useState<boolean>(false);
    const [isUsed, setIsUsed] = React.useState<boolean>(true);
    const [code, setCode] = React.useState<string>("");
    const [error, setError] = React.useState(false);
    const openInfo = () => { setInfoActive(true); }
    const closeInfo = () => { setInfoActive(false); }
    const [errorMessage, setErrorMessage] = React.useState<string>("")

    const actionHandler = async() => {
      
      checkLinkPresence(code, "input")
    }
    const checkLinkPresence = async(link: string, mode :string) => {
      livstickApp.code = link;
        const _code = await livstickApp.triage();
        if (_code.status == "200" || _code.code === "200") {
    
          _code.type == "LIVSTICK_L" && mode === "url" ?  (window.location.href = _code.link, setIsUsed(true)) :( history.push("/sortUrl"), setIsUsed(false))
          setError(false)
        }
        else {        
          setError(true)
          setErrorMessage("Code does not exist")
        }
    }

    const _handleKeyDown = function(e) {
      if (e.key === 'Enter' && livstickApp.code != "NOCODE" || "" || undefined) {
        actionHandler();
      }
    }
    
    const start = async () => {
      match.params.id ? checkLinkPresence(match.params.id, "url") : setIsUsed(false)
    };
    
    React.useEffect(() => {       
      start();
    }, [])
    
   return( 
     <>

    {!isUsed && <Layout>
      {/* default email or specify*/}

      {/* params : quote*/}
      <LandingWrapper>
        <LandingContent>
        <Catchline dangerouslySetInnerHTML={{__html: "Linking a Page to a (QR) code"}}></Catchline>
          <LandingMessage>Type the code of your card</LandingMessage>
          <LandingFormContainer>
            <LandingForm>
              <InputGroup>
                <InputLabel>Your Code</InputLabel>
                <InputField onChange={(e) => setCode(e.currentTarget.value)} onKeyPress={_handleKeyDown}></InputField> {/* variable : lp_txt_code */}
                <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive}></InputInfoIcon>  
                <InputInfo active={InfoActive}>This is the Code printed on the card below the QR Code</InputInfo>
              </InputGroup>
              { error && <ErrorInfo>{errorMessage}</ErrorInfo>}
              <ButtonGroup>
                <Button color={colors.main} hover="main" onClick={actionHandler}>Send</Button>
              </ButtonGroup>
            </LandingForm>
            </LandingFormContainer>
        </LandingContent>
      </LandingWrapper>

    </Layout>}
    </>
    )
}

export default LandingPage;