import * as React from 'react';
import { LanguagesWrapper, LanguagesSelectElement, LanguagesSelect, LanguagesOption } from './styled';

import livstickApp from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  return <LanguagesWrapper>
    <LanguagesSelectElement>
      <LanguagesSelect onChange={e => {
        livstickApp.changeLang(e.currentTarget.value);
      }}>
        <LanguagesOption selected={navigator.language.split(/[-_]/)[0] == 'fr'} value="fr">Fr</LanguagesOption>
        <LanguagesOption selected={navigator.language.split(/[-_]/)[0] == 'en'} value="en">En</LanguagesOption>
        <LanguagesOption selected={navigator.language.split(/[-_]/)[0] == 'de'} value="de">De</LanguagesOption>
        <LanguagesOption selected={navigator.language.split(/[-_]/)[0] == 'it'} value="it">It</LanguagesOption>
        <LanguagesOption selected={navigator.language.split(/[-_]/)[0] == 'es'} value="es">Es</LanguagesOption>
        <LanguagesOption selected={navigator.language.split(/[-_]/)[0] == 'ru'} value="ru">Ru</LanguagesOption>
      </LanguagesSelect>
    </LanguagesSelectElement>
  </LanguagesWrapper>;
}

export default Languages;
