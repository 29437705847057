import * as React from 'react';
import Layout from '../layout';
import { InputLabel, IconLanding, LandingWrapper, Catchline, Sep, LandingMessage, LandingTitle, LandingContent, LandingFormContainer, LandingForm, InputField, InputGroup, ButtonGroup, InputInfoIcon, InputInfo } from './styled';
import { Button } from '../../widgets/globaleStyledWidget';
import { colors } from '../../variables';
import imgLanding from './images/ico-gift.png';

import {FormattedMessage, useIntl} from 'react-intl';
import { match, useHistory } from 'react-router-dom';
import livstickApp from '../../models/livstick';
import { ErrorInfo, SuccessInfo } from '../message/styled';
import axios from 'axios';


interface DetailParams {
  id: string;
}

interface DetailsProps {
  match?: match<DetailParams>;
}

const SortPage:React.FunctionComponent<DetailsProps> = ({match}) => {
    const intl = useIntl();
    const history = useHistory();
    /* Info Input Number */
    const [InfoNumberActive, setInfoNumberActive] = React.useState<boolean>(false);
    const [isAllocated, setIsAllocated] = React.useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [statusCode, setStatusCode] = React.useState<string>("");
    const [informationMessage, setInformationMessage] = React.useState<string>("")
    
    const [showUrl, setShowUrl] = React.useState<boolean>(false);

    const openNumberInfo = () => { setInfoNumberActive(true); }
    const closeNumberInfo = () => { setInfoNumberActive(false); }    

    /* Info Input */
    const [InfoActive, setInfoActive] = React.useState<boolean>(false);
    const [code, setCode] = React.useState<string>();
    const [url, setUrl] = React.useState<string>("");
    const [error, setError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const openInfo = () => { setInfoActive(true); }
    const closeInfo = () => { setInfoActive(false); }


    React.useEffect(() => {
      setCode(livstickApp.code)
      start()
    }, [])

    const start = async () => {
    
        const _code = await livstickApp.triage();
        console.log("effect_sort", _code);  
        setDataLoaded(true)
        switch (_code.status) {
          case "200":
            setUrl(_code.link)
            setIsAllocated(true)
            break;
          case "504":
          case "505": 
            setIsAllocated(false)
          break;
          default:
            console.log("default case");
            
            setIsAllocated(false)
            break;
        }
    };

    const  actionHandler = async () => {
        axios.get(`/livstick/push_text.php?code=${livstickApp.code}&email=test@test.fr&optin=1&link=${url}&answer=0&newsletter=0`)
        .then(res => {
          livstickApp.message = url;
          setSuccess(true)
          setInformationMessage("Code is well registered")
          setError(false)
        })
        .catch(err => {
          setSuccess(false)
          setError(true)
          setInformationMessage("Code does not exist")
        })
      }
      const _handleKeyDown = function(e) {
      if (e.key === 'Enter' && livstickApp.code != "NOCODE" || "" || undefined) {
        actionHandler();
      }
    }
    return <Layout>
      {/* default email or specify*/}

      {/* params : quote*/}
      {/* define langage variable*/}
      {/* manage case not found*/}
      <LandingWrapper>
        <LandingContent>
        <Catchline dangerouslySetInnerHTML={{__html: "Setup your link"}}></Catchline>
          <LandingMessage>Paste the URL you want to associate to your code</LandingMessage>
          <LandingFormContainer>
            <LandingForm>
              <InputGroup>
                <InputLabel>Code</InputLabel>
                <InputField value={code} onChange={(e) => setCode(e.currentTarget.value)} onKeyPress={_handleKeyDown}></InputField> {/* variable : lp_txt_code */}
                <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive}></InputInfoIcon>  
                <InputInfo active={InfoActive}><FormattedMessage id="lp_txt_info"/></InputInfo>
              </InputGroup>
             <InputGroup>
                <InputLabel>Link</InputLabel>
                <InputField value={url} onChange={(e) => setUrl(e.currentTarget.value)} onKeyPress={_handleKeyDown}></InputField>
              </InputGroup>
              { error && <ErrorInfo>{informationMessage}</ErrorInfo> }
             { success && <SuccessInfo>{informationMessage}</SuccessInfo> }
              <ButtonGroup>
                <Button color={colors.main} hover="main" onClick={actionHandler}>save</Button>
              </ButtonGroup>
            </LandingForm>
            </LandingFormContainer>
        </LandingContent>
      </LandingWrapper>

    </Layout>;
}

export default SortPage;