import * as React from 'react';
import { FooterWrapper, ListFooterLink } from './styled';
import { Link } from 'react-router-dom';

import {FormattedMessage} from 'react-intl';


const Footer:React.FunctionComponent = () => {
  
    return <FooterWrapper>
      {/* Suppression de l'alcool .... */}
      <ListFooterLink className="right">
        <li><a href="http://www.meemento.com" target="_blank">Powered by Meemento</a></li>
        <li><a href="https://www.meemento.com/docs/ml.pdf" target="_blank">Legal</a></li>
        <li><a href="https://www.meemento.com/docs/cgu.pdf" target="_blank">Terms of Use</a></li>
        <li><a href="https://www.meemento.com/docs/pc.pdf" target="_blank">Data Protection</a></li>
      </ListFooterLink>
      
    </FooterWrapper>;
}

export default Footer;